import React, { useCallback } from "react";
import {
  Control,
  Controller,
  FormState,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormReset,
} from "react-hook-form";

import { FormInput } from "components/UI/Form/FormInput";
import { FormSelect } from "components/UI/Form/FormSelect";
import { FormRangePicker } from "components/UI/Form/FormRangePicker";
import { Filters } from "components/Filters";
import {
  CityType,
  DepartmentType,
  VacancyStatusesType,
} from "graphql/types/types";
import { filterOption, getSelectOptions } from "components/UI/Select/helpers";
import { COUNT_CANDIDATES_OPTIONS } from "common/const/options";
import { VacancyFiltersState } from "../../index";
import { INITIAL_STATE_FILTERS_VACANCY } from "common/const/filters";

type Props = {
  loading: boolean;
  vacancyStatuses: VacancyStatusesType[];
  department: DepartmentType[];
  cities: CityType[];
  onFilter: (filtersData: VacancyFiltersState) => void;
  handleSubmit: UseFormHandleSubmit<VacancyFiltersState, undefined>;
  reset: UseFormReset<VacancyFiltersState>;
  control: Control<VacancyFiltersState>;
  formState: FormState<VacancyFiltersState>;
  className?: string;
  setVacancyFilters: (filtersData: VacancyFiltersState) => void;
};

export const VacancyFilters: React.FC<Props> = ({
  loading,
  vacancyStatuses,
  department,
  cities,
  onFilter,
  handleSubmit,
  reset,
  control,
  formState,
  className,
  setVacancyFilters,
}) => {
  const onSubmit = useCallback<SubmitHandler<VacancyFiltersState>>(
    (filtersData: VacancyFiltersState) => {
      onFilter(filtersData);
    },
    [onFilter]
  );

  const handleReset = () => {
    setVacancyFilters(INITIAL_STATE_FILTERS_VACANCY);
    reset(INITIAL_STATE_FILTERS_VACANCY);
    onFilter(INITIAL_STATE_FILTERS_VACANCY);
  };

  return (
    <Filters
      className={className}
      isDirty={formState.isDirty}
      isError={Boolean(Object.values(formState.errors).length)}
      count={Object.values(formState.dirtyFields).length}
      loading={loading}
      onSubmit={handleSubmit(onSubmit)}
      onReset={handleReset}
    >
      <Controller
        name="vacancyId"
        control={control}
        render={({ field, fieldState }) => (
          <FormInput
            type={"number"}
            label="ID вакансии"
            placeholder="Введите ID вакансии"
            error={fieldState.error?.message}
            {...field}
          />
        )}
      />
      <Controller
        name="creationDateRange"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormRangePicker
            label="Дата создания"
            placeholder={["c", "по"]}
            format="DD-MM-YYYY"
            style={{ width: "370px" }}
            error={error?.message}
            {...field}
          />
        )}
      />
      <Controller
        name="statuses"
        control={control}
        render={({ field }) => (
          <FormSelect
            label="Статус вакансии"
            placeholder="Выберите статус(ы)"
            mode="multiple"
            options={getSelectOptions(vacancyStatuses)}
            {...field}
          />
        )}
      />
      <Controller
        name="candidateCount"
        control={control}
        render={({ field }) => (
          <FormSelect
            label="Количество кандидатов"
            placeholder="Выберите диапазон"
            options={COUNT_CANDIDATES_OPTIONS}
            {...field}
          />
        )}
      />
      <Controller
        name="city"
        control={control}
        render={({ field }) => (
          <FormSelect
            label="Город"
            showSearch
            filterOption={filterOption}
            optionFilterProp="children"
            placeholder="Выберите город"
            options={cities.map(({ id, city }) => ({
              label: city,
              value: id,
            }))}
            {...field}
          />
        )}
      />
      <Controller
        name="department"
        control={control}
        render={({ field }) => (
          <FormSelect
            label="Департамент"
            placeholder="Выберите департамент"
            options={getSelectOptions(department)}
            {...field}
          />
        )}
      />
    </Filters>
  );
};
