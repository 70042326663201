import { createSelector } from "reselect";

import { PAGINATION } from "common/const/pagination";
import { RootState } from "services/store/rootReducer";

const selectRepliesNew = (state: RootState) => state.repliesNew;

export const selectRepliesNewList = createSelector(
  selectRepliesNew,
  (state) => state.repliesNewList
);

export const selectRepliesNewListMeta = createSelector(
  selectRepliesNewList,
  (state) => state.meta
);
export const selectRepliesNewListPagination = createSelector(
  selectRepliesNewListMeta,
  (state) =>
    state
      ? {
          offset: state.offset,
          limit: state.limit,
        }
      : PAGINATION.REPLIES
);

export const selectRepliesNewListData = createSelector(
  selectRepliesNewList,
  (state) => state.data
);
export const selectRepliesNewListLoading = createSelector(
  selectRepliesNewList,
  (state) => state.loading
);

export const selectReplyNew = createSelector(
  selectRepliesNew,
  (state) => state.replyNew.data
);
export const selectReplyNewLoading = createSelector(
  selectRepliesNew,
  (state) => state.replyNew.loading
);
