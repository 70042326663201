import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames/dedupe";

import {
  selectCandidateList,
  selectCandidateListData,
  selectCandidateListMeta,
  selectSuitableMetaIsLastPage,
} from "services/store/modules/candidates/selectors";
import { selectCandidateData } from "services/store/modules/candidate/selectors";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { Title } from "components/UI/Title";
import { VacancyInfo } from "components/VacancyInfo";
import { ROUTE } from "routes";
import { PAGINATION } from "common/const/pagination";
import { CandidatesTable } from "components/CandidatesTable";
import { useToggle } from "common/hooks/useToggle";
import { PublishModal } from "./components/PublishModal";
import { Platform } from "common/const/platform";
import { setSessionIds } from "common/utils/cookies";
import {
  PaginationCursorType,
  ReplyNewClientType,
  VacancyType,
} from "graphql/types/types";
import { useTimeout } from "common/hooks/useTimeout";
import { useChangeVisible } from "common/hooks/visible/useChangeVisible";
import { ModalConfirm } from "components/ModalConfirm";
import { setVacancy } from "services/store/modules/vacancy";
import { selectVacancyLoading } from "services/store/modules/vacancy/selectors";
import { userList } from "services/store/modules/user/selectors";
import { authInfo } from "services/store/modules/auth";
import { getUrlParams } from "common/utils/routes";
import { selectCallsError } from "services/store/modules/calls";
import { stringifyQuery } from "common/utils/query";
import { Button } from "components/UI/Button";
import { fetchCandidateSuitableList } from "services/store/modules/candidates/action";
import { clearCandidateList } from "services/store/modules/candidates";
import { getReplyNew } from "services/store/modules/repliesNew/actions";
import { setCandidate } from "services/store/modules/candidate";
import toaster from "components/UI/Notifications/Notification";
import { selectReplyNew } from "services/store/modules/repliesNew/selectors";
import {
  CONFIRM_MESSAGE,
  MAX_TIME_VACANCY_BLOCK,
  MIN_CANDIDATES_FOR_TABLE_SIZES,
} from "./constants";

import "./styles.scss";

interface Props {
  vacancyId: string;
  vacancy: VacancyType;
}

export const Vacancy: React.FC<Props> = ({ vacancyId, vacancy }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { onChangeVacancyVisible } = useChangeVisible({ id: vacancy.id });

  const { loading: candidateLoading } = useAppSelector(selectCandidateList);
  const candidatesList = useAppSelector(selectCandidateListData);
  const { limit, total, cursor } = useAppSelector(selectCandidateListMeta);
  const isLastPage = useAppSelector(selectSuitableMetaIsLastPage);
  const vacancyLoading = useAppSelector(selectVacancyLoading);
  const managerList = useAppSelector(userList);
  const userData = useAppSelector(authInfo);
  const candidate = useAppSelector(selectCandidateData);
  const reply = useAppSelector(selectReplyNew);

  const { fromRecommended, fromReply } = getUrlParams();
  const callsError = useAppSelector(selectCallsError);

  const [openPublishModal, togglePublishModal] = useToggle(false);
  const [publishItems, setPublishItems] = useState<Platform[]>(["rabotut"]);

  const handleNotVisible = async () => {
    const result = await onChangeVacancyVisible({ inWork: false }).unwrap();

    if (result?.changeVisibleVacancy) {
      dispatch(
        setVacancy({
          vacancy: { ...result.changeVisibleVacancy, peopleCountOpen: null },
        })
      );
    }
  };
  useTimeout(handleNotVisible, MAX_TIME_VACANCY_BLOCK);

  const handleRowClick = (candidateId: string) => {
    setSessionIds("openedVacancyCandidateIds", candidateId);
    history.push({
      pathname: `${ROUTE.CANDIDATE}/${candidateId}`,
      search: stringifyQuery({
        fromVacancy: vacancyId,
        ...(vacancy.inWork ? { fromVacancyInWork: vacancyId } : {}),
      }),
    });
  };

  const getCandidateList = useCallback(
    (pagination: PaginationCursorType = PAGINATION.VACANCY) => {
      dispatch(
        fetchCandidateSuitableList({
          pagination: { cursor: pagination.cursor, limit: pagination.limit },
          filter: { vacancyId },
        })
      );
    },
    [dispatch, vacancyId]
  );
  const handleClick = () => {
    getCandidateList({
      cursor,
      limit,
    });
  };
  const getReply = async (id: ReplyNewClientType["id"]) => {
    try {
      const res = await dispatch(getReplyNew({ id })).unwrap();
      dispatch(setCandidate(res.replyNew.candidate));
    } catch (error) {
      toaster.error({ title: "Ошибка загрузки отклика" });
    }
  };

  useEffect(() => {
    if (fromReply && !reply) {
      getReply(fromReply);
    }
  }, [fromReply]);

  useEffect(() => {
    const isUpdateList = Number(vacancyId) !== vacancy.id;
    if (!candidatesList.length || isUpdateList) {
      if (isUpdateList) {
        dispatch(clearCandidateList());
      }
      getCandidateList();
    }
  }, []);

  return (
    <div className="vacancy-container">
      {userData && (
        <VacancyInfo
          vacancy={vacancy}
          className="vacancy-container-info"
          publishItems={publishItems}
          managerList={managerList}
          mapLoading={vacancyLoading}
          changeItems={(val) => setPublishItems(val)}
          openModal={togglePublishModal}
          userData={userData}
          hiddenActions={Boolean(fromRecommended)}
        />
      )}

      <Title type="h3">Анкеты кандидатов</Title>

      <CandidatesTable
        className="vacancy-container-table"
        tableClassName={cn({
          "vacancy-container-table-candidates":
            candidatesList.length < MIN_CANDIDATES_FOR_TABLE_SIZES ||
            (fromRecommended && candidate),
        })}
        candidatesList={
          fromRecommended && candidate ? [candidate] : candidatesList
        }
        vacancyId={Number(vacancyId)}
        handleRowClick={handleRowClick}
        callsError={callsError}
        loading={candidateLoading}
        emptyText={
          Math.floor(total / limit) <= 1
            ? "На данный момент другие кандидаты отсутствуют"
            : "Перейдите на следующую страницу списка кандидатов"
        }
      />

      {!fromRecommended && total ? (
        <Button
          className="vacancy-container-loading"
          loading={candidateLoading}
          disabled={isLastPage}
          onClick={handleClick}
        >
          Загрузить еще
        </Button>
      ) : null}

      {userData && (
        <PublishModal
          open={openPublishModal}
          vacancy={vacancy}
          publishItems={publishItems}
          onChangeOpen={togglePublishModal}
          userData={userData}
        />
      )}

      <ModalConfirm
        message={CONFIRM_MESSAGE}
        blackRoute={`${ROUTE.CANDIDATE}/:id`}
        isFactor={Boolean(vacancy.peopleCountOpen)}
        onNavigate={handleNotVisible}
      />
    </div>
  );
};
