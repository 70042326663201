import React, { useEffect } from "react";

import { ContentLayout } from "layouts/ContentLayout";
import { Replies } from "components/redezign/Replies";
import { useAppDispatch } from "services/store/store";
import { fetchDailyPlanList } from "services/store/modules/vacanciesList/actions";

export const RepliesPage: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchDailyPlanList({ date: new Date() }));
  }, [dispatch]);

  return <ContentLayout centralColumn={{ content: <Replies /> }} />;
};
