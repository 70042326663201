import React, { useEffect } from "react";
import { Controller, Control, FieldErrors, useWatch } from "react-hook-form";
import { getMetroDadata } from "api/dadata";
import { UseFormSetValue } from "react-hook-form/dist/types/form";

import { Title } from "components/UI/Title";
import RowLayout from "layouts/RowLayout";
import { FormInput } from "components/UI/Form/FormInput";
import { FormSelect } from "components/UI/Form/FormSelect";
import { FormCheckbox } from "components/UI/Form/FormCheckbox";
import { OPTION_CAR } from "common/const/options";
import { OPTION_CATEGORY_OF_CAR } from "../constants/checkboxOptions";
import { FormSelectDadata } from "components/UI/Form/FormSelectDadata";
import FormTag, { TagData } from "components/UI/Form/FormTag";
import { getActualAddressErrorMessage } from "../helpers";
import { FiltersFormState } from "../types";

import "./styles.scss";

interface CandidateContactsProps {
  control: Control<FiltersFormState>;
  disabledForm: boolean;
  errors: FieldErrors<FiltersFormState>;
  setValue: UseFormSetValue<FiltersFormState>;
}

const CandidateContacts: React.FC<CandidateContactsProps> = ({
  control,
  disabledForm,
  errors,
  setValue,
}) => {
  const actualAddress = useWatch({
    control,
    name: "actualAddress",
  });
  const metroList = useWatch({
    control,
    name: "metro",
  });

  useEffect(() => {
    const fetchMetroData = async () => {
      if (actualAddress) {
        const metroData = await getMetroDadata(actualAddress.value);
        setValue("metro", metroData);
      }
    };

    fetchMetroData();
  }, [actualAddress, setValue]);

  return (
    <div className="form-candidate-details">
      <Title type={"h2"} color="blue">
        Контактные данные
      </Title>
      <RowLayout
        leftComponent={
          <Controller
            name="email"
            control={control}
            render={({ field, formState: { errors } }) => (
              <FormInput
                label={"Адрес электронной почты"}
                placeholder={"Укажите почту"}
                error={errors.email?.message}
                disabled={disabledForm}
                {...field}
              />
            )}
          />
        }
      />
      <RowLayout
        centerSpan={16}
        leftComponent={
          <Controller
            name="region"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <FormInput label="Регион" disabled={true} {...field} />
            )}
          />
        }
        centerComponent={
          <Controller
            name="actualAddress"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormSelectDadata
                label={"Фактический адрес"}
                required={true}
                disabled={disabledForm}
                error={getActualAddressErrorMessage(errors)}
                inputProps={{
                  onChange: (event) => {
                    const value = (event.target as HTMLInputElement).value;
                    if (!value) {
                      field.onChange({});
                    }
                  },
                }}
                {...field}
              />
            )}
          />
        }
      />
      <RowLayout
        leftSpan={16}
        leftComponent={
          metroList.length > 0 && (
            <FormTag
              label="Метро"
              tags={metroList.map(
                ({ name, distance }): TagData => ({
                  name: `${name} ${distance} км`,
                  color: "default",
                })
              )}
            />
          )
        }
      />
      <Title type={"h3"} color="blue">
        Место для отправки зарплатной карты
      </Title>
      <RowLayout
        centerSpan={16}
        leftComponent={
          <Controller
            name="cardDeliveryRegion"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <FormInput label="Регион" disabled={true} {...field} />
            )}
          />
        }
        centerComponent={
          <Controller
            name="cardDeliveryAddress"
            rules={{ required: false }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormSelectDadata
                label={"Адрес"}
                disabled={true}
                inputProps={{
                  onChange: (event) => {
                    const value = (event.target as HTMLInputElement).value;
                    if (!value) {
                      onChange({});
                    }
                  },
                }}
                value={value}
                onChange={onChange}
              />
            )}
          />
        }
      />
      <RowLayout
        leftComponent={
          <Controller
            name="haveCar"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <FormSelect
                label={"Наличие автомобиля"}
                options={OPTION_CAR}
                disabled={disabledForm}
                {...field}
              />
            )}
          />
        }
        centerComponent={
          <Controller
            name="driverLicense"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <FormCheckbox
                label={"Открытые категории прав"}
                options={OPTION_CATEGORY_OF_CAR}
                style={{ width: "200%" }}
                disabled={disabledForm}
                {...field}
              />
            )}
          />
        }
      />
    </div>
  );
};

export default CandidateContacts;
