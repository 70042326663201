import React, { useCallback, useEffect } from "react";
import { Moment } from "moment";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { FormInput } from "components/UI/Form/FormInput";
import { FormSelect } from "components/UI/Form/FormSelect";
import FormDatePicker from "components/UI/Form/FormDatePicker";
import { CandidateStatus, CandidateStatusesType } from "graphql/types/types";
import { getSelectOptions } from "components/UI/Select/helpers";
import { FormTreeSelect } from "components/UI/Form/FormTreeSelect";
import { IDirectoryTreeItem } from "services/store/modules/directory/types";
import { getAreaTreeOptions } from "components/UI/TreeSelect/helpers";
import { Filters } from "components/Filters";
import { useAppDispatch } from "services/store/store";
import { fetchCandidateForAdaptationList } from "services/store/modules/candidatesForAdaptation/actions";
import { preparePayload } from "../../helpers";

type Props = {
  className?: string;
  loading: boolean;
  candidateStatuses: CandidateStatusesType[];
  areasOptions: IDirectoryTreeItem[];
};

export type FiltersState = {
  vacancyId: string;
  dateTransfer: Moment | null;
  city: string | null;
  status: CandidateStatus | null;
};

const initialState: FiltersState = {
  vacancyId: "",
  dateTransfer: null,
  city: null,
  status: null,
};

export const AdaptationFilters: React.FC<Props> = ({
  candidateStatuses,
  areasOptions,
  loading,
}) => {
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { isDirty, dirtyFields, errors },
  } = useForm<FiltersState>({
    defaultValues: initialState,
  });

  const updateCandidateForm: SubmitHandler<FiltersState> = useCallback(
    (payload: FiltersState) => getAdaptationList(payload),
    []
  );

  const handleReset = () => reset(initialState);

  const getAdaptationList = (payload: FiltersState) =>
    dispatch(fetchCandidateForAdaptationList(preparePayload(payload)));

  useEffect(() => {
    setValue("status", CandidateStatus.TransferredMa);
    getAdaptationList(getValues());
  }, []);

  return (
    <Filters
      isDirty={isDirty}
      isError={Boolean(Object.values(errors).length)}
      count={Object.values(dirtyFields).length}
      loading={loading}
      onSubmit={handleSubmit(updateCandidateForm)}
      onReset={handleReset}
    >
      <Controller
        name="vacancyId"
        control={control}
        render={({ field }) => (
          <FormInput
            placeholder="Введите ID"
            label="ID вакансии"
            style={{ width: "160px" }}
            {...field}
          />
        )}
      />
      <Controller
        name="dateTransfer"
        control={control}
        render={({ field }) => (
          <FormDatePicker
            placeholder="Выберите дату"
            format="DD-MM-YYYY"
            label="Дата перевода"
            style={{ width: "160px" }}
            {...field}
          />
        )}
      />

      <Controller
        name="city"
        control={control}
        render={({ field }) => (
          <FormTreeSelect
            label="Регион вакансии"
            placeholder="Выберите регион"
            style={{ width: "320px" }}
            treeData={getAreaTreeOptions(areasOptions)}
            allowClear={true}
            treeDefaultExpandAll={true}
            showSearch={true}
            {...field}
          />
        )}
      />

      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <FormSelect
            style={{ width: "320px" }}
            placeholder="Выберите статус"
            label="Статус кандидата"
            options={getSelectOptions(candidateStatuses)}
            {...field}
          />
        )}
      />
    </Filters>
  );
};
