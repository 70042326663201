import React from "react";
import { ColumnsType } from "antd/lib/table";
import cn from "classnames/dedupe";

import { Table } from "components/UI/Table";
import { PageInfoType, VacancyType } from "graphql/types/types";
import { Pagination, PaginationPayload } from "components/UI/Pagination";
import { getTableRowDisabled } from "common/helpers/table";
import { DisabledVacancies } from "services/store/modules/vacanciesList/types";

type Props = {
  vacancies: VacancyType[];
  meta: PageInfoType;
  columns?: ColumnsType<VacancyType>;
  rowClassName?: string;
  disabledVacancyList?: DisabledVacancies;
  userId?: number;
  onRowClick?: (vacancyId: VacancyType["id"]) => void;
  onPaginationClick?: (payload: PaginationPayload) => void;
};

export const VacanciesTable: React.FC<Props> = ({
  vacancies,
  columns,
  meta,
  rowClassName,
  disabledVacancyList = [],
  userId,
  onRowClick,
  onPaginationClick,
}) => {
  return (
    <>
      <Table
        tableLayout="fixed"
        dataSource={vacancies}
        columns={columns}
        onRow={(vacancy) => ({
          onClick: () => onRowClick?.(vacancy.id),
          className: cn(rowClassName, {
            "vacancies-container-row--disabled":
              getTableRowDisabled({
                id: vacancy.id,
                disabledList: disabledVacancyList,
                ownerId: userId,
              }) || Boolean(vacancy.inBlocked),
          }),
        })}
      />
      <Pagination
        className="candidates-container-pagination"
        total={meta?.total}
        limit={meta?.limit}
        offset={meta?.offset}
        onChange={(pagination) => onPaginationClick?.(pagination)}
      />
    </>
  );
};
