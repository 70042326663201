import React, { useCallback, useEffect, useMemo } from "react";

import { useAppSelector } from "services/store/store";
import { useAppDispatch } from "services/store/store";
import { Spinner } from "components/UI/Spinner";
import {
  selectActiveCategory,
  selectCategoryById,
  selectDailyPlanListReplies,
  selectPositionCategoriesReplies,
  selectReplies,
  selectRepliesLoading,
  selectRepliesMeta,
} from "services/store/modules/repliesList/selectors";
import { PaginationPayload } from "components/UI/Pagination";
import { setActiveCategory } from "services/store/modules/repliesList";
import { PlanTable } from "components/PlanTable";
import { selectDailyPlanListLoading } from "services/store/modules/vacanciesList/selectors";
import { getReplyListByCategory } from "services/store/modules/repliesList/actions";
import { RepliesTable } from "./components/RepliesTable";
import { QueryReplyListByCategoryArgs } from "graphql/types/types";
import { getClaimsList } from "services/store/modules/claimsList/actions";
import {
  selectClaims,
  selectClaimsLoading,
  selectClaimsMeta,
} from "services/store/modules/claimsList/selectors";
import { ClaimsTable } from "./components/ClaimsTable";
import { CLAIM_CATEGORY_ID } from "common/helpers/transformAndSortCategories";
import { PAGINATION } from "common/const/pagination";

import "./styles.scss";

type Props = {
  className?: string;
};

export const Replies: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const loading = useAppSelector(selectDailyPlanListLoading);
  const replies = useAppSelector(selectReplies);
  const { activeCategory } = useAppSelector(selectActiveCategory);
  const positionCategories = useAppSelector(selectPositionCategoriesReplies);
  const meta = useAppSelector(selectRepliesMeta);
  const categoryById = useAppSelector(selectCategoryById);
  const repliesLoading = useAppSelector(selectRepliesLoading);
  const dailyPlanList = useAppSelector(selectDailyPlanListReplies);

  const claims = useAppSelector(selectClaims);
  const claimsMeta = useAppSelector(selectClaimsMeta);
  const claimsLoading = useAppSelector(selectClaimsLoading);

  const getReplies = useCallback(
    (payload: QueryReplyListByCategoryArgs) => {
      dispatch(getReplyListByCategory(payload));
    },
    [dispatch]
  );
  const handleCategoryClick = useCallback(
    (
      categoryId: number,
      pagination: PaginationPayload = PAGINATION.REPLIES
    ) => {
      dispatch(setActiveCategory(categoryId));
      if (categoryId === CLAIM_CATEGORY_ID) {
        dispatch(getClaimsList({ pagination }));
      } else {
        getReplies({ pagination, categoryId: Number(categoryId) });
      }
    },
    [dispatch, getReplies]
  );
  useEffect(() => {
    if (activeCategory && meta) {
      getReplies({
        pagination: {
          offset: meta.offset,
          limit: meta.limit,
        },
        categoryId: Number(activeCategory),
      });
    }
  }, [dispatch, getReplies]);

  const renderTable = useMemo(() => {
    if (categoryById) {
      return (
        <RepliesTable
          replies={replies}
          meta={meta}
          loading={repliesLoading}
          categoryById={categoryById}
          activeCategory={activeCategory}
          onChangePagination={handleCategoryClick}
        />
      );
    }

    if (claims.length) {
      return (
        <ClaimsTable
          claims={claims}
          loading={claimsLoading}
          meta={claimsMeta}
          onChangePagination={handleCategoryClick}
          activeCategory={activeCategory}
        />
      );
    }

    return null;
  }, [
    categoryById,
    claims,
    replies,
    meta,
    repliesLoading,
    claimsLoading,
    claimsMeta,
    activeCategory,
    handleCategoryClick,
  ]);

  return (
    <div className="replies-container">
      {loading ? (
        <Spinner className="replies-container-spinner" loading={loading} />
      ) : (
        <>
          <PlanTable
            dailyPlanList={dailyPlanList}
            positionCategories={positionCategories}
            activeCategory={activeCategory}
            onClick={handleCategoryClick}
          />

          {renderTable}
        </>
      )}
    </div>
  );
};
