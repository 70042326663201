import { SelectProps } from "antd";

export const filterOption: SelectProps["filterOption"] = (input, option) => {
  const optionLabel = (option?.label as unknown as string) ?? "";
  return optionLabel.toLowerCase().includes(input.toLowerCase());
};

type Options = Array<{
  id?: string | number | null;
  key?: string;
  name?: string;
  fullName?: string;
}>;

export const getSelectOptions = <T extends Options>(
  options: T,
  isKey?: boolean
) => {
  return options.map(({ name, id, key, fullName }) => ({
    label: name || fullName,
    value: isKey ? key : id || key,
  }));
};
