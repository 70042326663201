import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../rootReducer";
import { ICall, ICallsState, IUpdateCallPayload } from "./types";
import { fillUsersHistory } from "../user/action";
import { normalizedDate } from "../../../../common/helpers/toISOStringWithTimezone";
import { IUser } from "../user/types";
import { IReply } from "../replies/types";
import { IFeedback } from "../claims/types";
import { client as apollo } from "../../../../graphql/apollo";
import { CREATE_HOT_CALL } from "../../../../graphql/requests/mutation/createHotCall";
import { UPDATE_HOT_CALL } from "../../../../graphql/requests/mutation/updateHotCall";
import { oktellDateParser } from "../../../../common/helpers/oktellDateParser";
import { HOT_CALL_LIST } from "../../../../graphql/requests/query/hotCallList";
import { HOT_CALL_STATUS_VALUE } from "../../../../common/const/hotCallStatuses";
import {
  setActiveCall,
  setCurrentCallOktellData,
  setCurrentHotCallData,
} from "../sockets";
import { AsyncThunkConfig, IMeta } from "../../types";
import axios from "axios";
import { camelize } from "../../../../common/helpers/keyConverter";
import { LOG_LEVEL } from "../../../logger/log";
import { sendLog } from "../auth";
import { toSwitchReplyStatus } from "../replies";
import toaster from "components/UI/Notifications/Notification";
import { CandidateType } from "graphql/types/types";

export const initialState: ICallsState = {
  replyHotCallList: [],
  replyActivityCallList: [],
  claimHotCallList: [],
  claimActivityCallList: [],
  isCallsLoading: false,
  isModalResult: false,
  currentCallReply: undefined,
  currentCallClaim: undefined,
  currentCallCandidate: undefined,
  error: null,
  replyHotCallMeta: {
    limit: 0,
    offset: 0,
    total: 10,
  },
  replyActivityCallMeta: {
    limit: 0,
    offset: 0,
    total: 10,
  },
  claimHotCallMeta: {
    limit: 0,
    offset: 0,
    total: 10,
  },
  claimActivityCallMeta: {
    limit: 0,
    offset: 0,
    total: 10,
  },
};

export const callsModule = createSlice({
  name: "calls",
  initialState,
  reducers: {
    setLoadingCalls(state: ICallsState, { payload }: PayloadAction<boolean>) {
      state.isCallsLoading = payload;
    },
    setErrorCalls(
      state: ICallsState,
      { payload }: PayloadAction<{ error: string | null }>
    ) {
      state.error = payload.error;
    },
    setModalResult(state: ICallsState, { payload }: PayloadAction<boolean>) {
      state.isModalResult = payload;
      if (!payload) {
        state.currentCallReply = undefined;
        state.currentCallClaim = undefined;
      }
    },
    setReplyHotCallList(
      state: ICallsState,
      { payload }: PayloadAction<ICall[]>
    ) {
      const list = state.replyHotCallList;
      state.replyHotCallList = [...list, ...payload];
    },
    setReplyActivityCallList(
      state: ICallsState,
      { payload }: PayloadAction<ICall[]>
    ) {
      const list = state.replyActivityCallList;
      state.replyActivityCallList = [...list, ...payload];
    },
    setClaimHotCallList(
      state: ICallsState,
      { payload }: PayloadAction<ICall[]>
    ) {
      const list = state.claimHotCallList;
      state.claimHotCallList = [...list, ...payload];
    },
    setClaimActivityCallList(
      state: ICallsState,
      { payload }: PayloadAction<ICall[]>
    ) {
      const list = state.claimActivityCallList;
      state.claimActivityCallList = [...list, ...payload];
    },
    clearReplyHotCallList(state: ICallsState) {
      state.replyHotCallList = [];
      state.replyHotCallMeta = {
        limit: 0,
        offset: 0,
        total: 10,
      };
    },
    clearReplyActivityCallList(state: ICallsState) {
      state.replyActivityCallList = [];
      state.replyActivityCallMeta = {
        limit: 0,
        offset: 0,
        total: 10,
      };
    },
    clearClaimHotCallList(state: ICallsState) {
      state.claimHotCallList = [];
      state.claimHotCallMeta = {
        limit: 0,
        offset: 0,
        total: 10,
      };
    },
    clearClaimActivityCallList(state: ICallsState) {
      state.claimActivityCallList = [];
      state.claimActivityCallMeta = {
        limit: 0,
        offset: 0,
        total: 10,
      };
    },
    //TODO: чистить при завершении звонка
    setCurrentCallReply(
      state: ICallsState,
      { payload }: PayloadAction<IReply | undefined>
    ) {
      state.currentCallReply = payload;
    },
    setCurrentCallClaim(
      state: ICallsState,
      { payload }: PayloadAction<IFeedback | undefined>
    ) {
      state.currentCallClaim = payload;
    },
    setCurrentCallCandidate(
      state: ICallsState,
      { payload }: PayloadAction<CandidateType | undefined>
    ) {
      state.currentCallCandidate = payload;
    },
    setReplyHotCallMeta(state: ICallsState, { payload }: PayloadAction<IMeta>) {
      state.replyHotCallMeta = payload;
    },
    setReplyActivityCallMeta(
      state: ICallsState,
      { payload }: PayloadAction<IMeta>
    ) {
      state.replyActivityCallMeta = payload;
    },
    setClaimHotCallMeta(state: ICallsState, { payload }: PayloadAction<IMeta>) {
      state.claimHotCallMeta = payload;
    },
    setClaimActivityCallMeta(
      state: ICallsState,
      { payload }: PayloadAction<IMeta>
    ) {
      state.claimActivityCallMeta = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCall.rejected, (state, action) => {
        state.error = action.payload || null;
      })
      .addCase(createCall.fulfilled, (state, { payload }) => {
        state.error = null;
      });
  },
});
// * mutations
export const {
  setLoadingCalls,
  setReplyHotCallList,
  setModalResult,
  setClaimHotCallList,
  setCurrentCallReply,
  setCurrentCallClaim,
  setCurrentCallCandidate,
  setReplyHotCallMeta,
  setClaimHotCallMeta,
  setReplyActivityCallList,
  setClaimActivityCallList,
  clearReplyHotCallList,
  clearClaimHotCallList,
  clearReplyActivityCallList,
  clearClaimActivityCallList,
  setClaimActivityCallMeta,
  setReplyActivityCallMeta,
  setErrorCalls,
} = callsModule.actions;

// * getters
export const replyHotCallList = (state: RootState) => {
  const userHistoryList = state.user.item.historyUserList;
  const activities = state.calls.replyHotCallList;
  const data = activities.map((item) => {
    const start = normalizedDate(item.startAt);
    const finish = normalizedDate(item.finishAt);
    return {
      ...item,
      manager: userHistoryList?.[item?.userId] as IUser,
      startAt: start,
      finishAt: finish,
    };
  });
  return data.sort(
    (a: ICall, b: ICall) =>
      new Date(b?.createdAt)?.valueOf() - new Date(a?.createdAt)?.valueOf()
  );
};

export const replyActivityCallList = (state: RootState) => {
  const userHistoryList = state.user.item.historyUserList;
  const activities = state.calls.replyActivityCallList;
  const data = activities.map((item) => {
    const start = normalizedDate(item.startAt);
    const finish = normalizedDate(item.finishAt);
    return {
      ...item,
      manager: userHistoryList?.[item?.userId] as IUser,
      startAt: start,
      finishAt: finish,
    };
  });
  return data.sort(
    (a: ICall, b: ICall) =>
      new Date(b?.createdAt)?.valueOf() - new Date(a?.createdAt)?.valueOf()
  );
};

export const claimHotCallList = (state: RootState) => {
  const userHistoryList = state.user.item.historyUserList;
  const activities = state.calls.claimHotCallList;
  const data = activities.map((item) => {
    const start = normalizedDate(item.startAt);
    const finish = normalizedDate(item.finishAt);
    return {
      ...item,
      manager: userHistoryList?.[item?.userId] as IUser,
      startAt: start,
      finishAt: finish,
    };
  });
  return data.sort(
    (a: ICall, b: ICall) =>
      new Date(b?.createdAt)?.valueOf() - new Date(a?.createdAt)?.valueOf()
  );
};

export const claimActivityCallList = (state: RootState) => {
  const userHistoryList = state.user.item.historyUserList;
  const activities = state.calls.claimActivityCallList;
  const data = activities.map((item) => {
    const start = normalizedDate(item.startAt);
    const finish = normalizedDate(item.finishAt);
    return {
      ...item,
      manager: userHistoryList?.[item?.userId] as IUser,
      startAt: start,
      finishAt: finish,
    };
  });
  return data.sort(
    (a: ICall, b: ICall) =>
      new Date(b?.createdAt)?.valueOf() - new Date(a?.createdAt)?.valueOf()
  );
};

export const isModalResult = (state: RootState) => state.calls.isModalResult;
export const isLoadingCallsSelector = (state: RootState) =>
  state.calls.isCallsLoading;

export const currentCallReply = (state: RootState) =>
  state.calls.currentCallReply;

export const replyHotCallMetaSelector = (state: RootState) =>
  state.calls.replyHotCallMeta;
export const replyActivityCallMetaSelector = (state: RootState) =>
  state.calls.replyActivityCallMeta;
export const claimHotCallMetaSelector = (state: RootState) =>
  state.calls.claimHotCallMeta;
export const claimActivityCallMetaSelector = (state: RootState) =>
  state.calls.claimActivityCallMeta;

export const currentCallClaim = (state: RootState) =>
  state.calls.currentCallClaim;
export const selectCallsError = (state: RootState) => state.calls.error;

// * actions
export const getReplyHotCallList = createAsyncThunk<
  void,
  { replyId?: number; offset: number },
  AsyncThunkConfig
>("calls/getReplyHotCallList", async (payload, thunkAPI) => {
  try {
    const replyId = payload?.replyId?.toString();
    const offset = payload?.offset || 0;
    const limit = 10;
    thunkAPI.dispatch(setLoadingCalls(true));
    if (replyId) {
      const res = await apollo.query({
        query: HOT_CALL_LIST,
        variables: {
          form: {
            sourceType: "REPLY",
            sourceId: replyId,
          },
          pagination: {
            offset: offset,
            limit: limit,
          },
        },
      });
      if (res?.data) {
        const list = res?.data?.hotCallList?.data || [];
        const preparedData = list.map((item: ICall) => ({
          ...item,
          userId: item?.managerId,
          duration:
            item?.startAt && item?.finishAt
              ? new Date(item.finishAt)?.valueOf() -
                new Date(item.startAt)?.valueOf()
              : 0,
        }));
        thunkAPI.dispatch(setReplyHotCallList(preparedData));
        thunkAPI.dispatch(fillUsersHistory());
        if (res.data?.hotCallList?.meta) {
          thunkAPI.dispatch(setReplyHotCallMeta(res.data?.hotCallList?.meta));
        }
      }
    } else {
      console.error("reply id is not define");
    }
  } catch (err) {
    console.error(err);
    thunkAPI.dispatch(setReplyHotCallList([]));
    toaster.error({
      title: err?.message || "Ошибка загрузки списка звонков",
    });
  } finally {
    thunkAPI.dispatch(setLoadingCalls(false));
  }
});

export const getReplyActivityCallList = createAsyncThunk<
  void,
  { replyId?: number; offset: number },
  AsyncThunkConfig
>("calls/getReplyActivityCallList", async (payload, thunkAPI) => {
  try {
    const replyId = payload?.replyId?.toString();
    const offset = payload?.offset || 0;
    const limit = 10;
    thunkAPI.dispatch(setLoadingCalls(true));
    const activityApiUrl = process.env.REACT_APP_HISTORY_API_URL;
    if (replyId) {
      const res = await axios.get(
        `${activityApiUrl}/call/?source_id=${replyId}&source_type=REPLY&limit=${limit}&offset=${offset}`
      );
      if (res?.data) {
        const list = res?.data?.items || [];
        const camelizeData = camelize(list);
        const preparedData = camelizeData.map((item: ICall) => ({
          ...item,
          userId: item?.managerId,
          duration:
            item?.startAt && item?.finishAt
              ? new Date(item.finishAt)?.valueOf() -
                new Date(item.startAt)?.valueOf()
              : 0,
        }));
        const meta = {
          limit: res?.data?.limit,
          offset: res?.data?.offset,
          total: res?.data?.total,
        };
        thunkAPI.dispatch(setReplyActivityCallMeta(meta));
        thunkAPI.dispatch(setReplyActivityCallList(preparedData));
        thunkAPI.dispatch(fillUsersHistory());
      }
    } else {
      console.error("reply id is not define");
      const logData = {
        level: LOG_LEVEL.ERROR,
        message: `reply id is not define`,
        field: "getReplyActivityCallList",
      };
      thunkAPI.dispatch(sendLog(logData));
    }
  } catch (err) {
    console.error(err);
    thunkAPI.dispatch(setReplyActivityCallList([]));
    toaster.error({
      title: err?.message || "Ошибка загрузки списка звонков",
    });
  } finally {
    thunkAPI.dispatch(setLoadingCalls(false));
  }
});

export const getClaimHotCallList = createAsyncThunk<
  void,
  { claimId?: number; offset: number },
  AsyncThunkConfig
>("calls/getClaimHotCallList", async (payload, thunkAPI) => {
  try {
    const claimId = payload?.claimId?.toString();
    const offset = payload?.offset || 0;
    const limit = 10;
    thunkAPI.dispatch(setLoadingCalls(true));
    if (claimId) {
      const res = await apollo.query({
        query: HOT_CALL_LIST,
        variables: {
          form: {
            sourceType: "CLAIM",
            sourceId: claimId,
          },
          pagination: {
            offset: offset,
            limit: limit,
          },
        },
      });
      if (res?.data) {
        const list = res?.data?.hotCallList?.data || [];
        const preparedData = list.map((item: ICall) => ({
          ...item,
          userId: item?.managerId,
          duration:
            item?.startAt && item?.finishAt
              ? new Date(item.finishAt)?.valueOf() -
                new Date(item.startAt)?.valueOf()
              : 0,
        }));
        thunkAPI.dispatch(setClaimHotCallList(preparedData));
        thunkAPI.dispatch(fillUsersHistory());
        if (res.data?.hotCallList?.meta) {
          thunkAPI.dispatch(setClaimHotCallMeta(res.data?.hotCallList?.meta));
        }
      }
    } else {
      console.error("claim id is not define");
    }
  } catch (err) {
    console.error(err);
    thunkAPI.dispatch(setClaimHotCallList([]));
    thunkAPI.dispatch(
      setClaimHotCallMeta({
        total: 0,
        limit: 0,
        offset: 0,
      })
    );
    toaster.error({
      title: err?.message || "Ошибка загрузки списка звонков",
    });
  } finally {
    thunkAPI.dispatch(setLoadingCalls(false));
  }
});

export const getClaimActivityCallList = createAsyncThunk<
  void,
  { claimId?: number; offset: number },
  AsyncThunkConfig
>("calls/getClaimActivityCallList", async (payload, thunkAPI) => {
  try {
    const claimId = payload?.claimId?.toString();
    const offset = payload?.offset || 0;
    const limit = 10;
    thunkAPI.dispatch(setLoadingCalls(true));
    const activityApiUrl = process.env.REACT_APP_HISTORY_API_URL;
    if (claimId) {
      const res = await axios.get(
        `${activityApiUrl}/call/?source_id=${claimId}&source_type=CLAIM&limit=${limit}&offset=${offset}`
      );
      if (res?.data) {
        const list = res?.data?.items || [];
        const camelizeData = camelize(list);
        const preparedData = camelizeData.map((item: ICall) => ({
          ...item,
          userId: item?.managerId,
          duration:
            item?.startAt && item?.finishAt
              ? new Date(item.finishAt)?.valueOf() -
                new Date(item.startAt)?.valueOf()
              : 0,
        }));
        const meta = {
          limit: res?.data?.limit,
          offset: res?.data?.offset,
          total: res?.data?.total,
        };
        thunkAPI.dispatch(setClaimActivityCallList(preparedData));
        thunkAPI.dispatch(setClaimActivityCallMeta(meta));
        thunkAPI.dispatch(fillUsersHistory());
      }
    } else {
      console.error("claim id is not define");
    }
  } catch (err) {
    console.error(err);
    thunkAPI.dispatch(setClaimActivityCallList([]));
    toaster.error({
      title: err?.message || "Ошибка загрузки списка звонков",
    });
  } finally {
    thunkAPI.dispatch(setLoadingCalls(false));
  }
});

export const createCall = createAsyncThunk<void, void, AsyncThunkConfig>(
  "calls/createCall",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { currentCallReply, currentCallClaim, currentCallCandidate } =
        getState().calls;
      const currentUser = getState()?.auth?.userData;
      const currentCallInfo = getState()?.sockets?.currentOktellCallData;
      const oktellUserLogin = getState()?.sockets?.oktellUserLogin;

      const type =
        (currentCallReply && "REPLY") ||
        (currentCallClaim && "CLAIM") ||
        (currentCallCandidate && "CANDIDATE") ||
        "CANDIDATE";

      const callItemByType = {
        REPLY: {
          sourceId: currentCallReply?.id,
          contacts: currentCallReply?.contacts,
        },
        CLAIM: {
          sourceId: currentCallClaim?.id,
          contacts: currentCallClaim?.contacts,
        },
        CANDIDATE: {
          sourceId: currentCallCandidate?.id,
          contacts: {
            email: currentCallCandidate?.email ?? "",
            fullName: currentCallCandidate?.fullName ?? "",
            phone: Array.isArray(currentCallCandidate?.contacts)
              ? currentCallCandidate?.contacts[0]?.contact ?? ""
              : "",
          },
        },
      };

      const { sourceId, contacts } = callItemByType[type] || {};

      const startTime = oktellDateParser(currentCallInfo?.starttime as string);
      if (sourceId && oktellUserLogin && currentUser?.id) {
        const payload = {
          status: HOT_CALL_STATUS_VALUE.START,
          managerId: currentUser?.id.toString(),
          managerRemoteId: oktellUserLogin,
          sourceId: sourceId.toString(),
          sourceType: type,
          startAt: startTime || new Date(),
          contacts: contacts,
        };
        await apollo.mutate({
          mutation: CREATE_HOT_CALL,
          variables: {
            form: payload,
          },
        });
      } else {
        console.error(
          "sourceId || oktellUserLogin || currentUser id is not define"
        );
      }
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateCall = createAsyncThunk<
  any,
  IUpdateCallPayload,
  AsyncThunkConfig
>("calls/updateCall", async (payload, { dispatch, getState }) => {
  const { id, form, action } = payload;

  dispatch(setLoadingCalls(true));
  try {
    const { currentCallReply, currentCallClaim, currentCallCandidate } =
      getState().calls;
    const currentUser = getState()?.auth?.userData;

    const type =
      (currentCallReply && "REPLY") ||
      (currentCallClaim && "CLAIM") ||
      (currentCallCandidate && "CANDIDATE") ||
      "CANDIDATE";

    const sourceIdByType = {
      REPLY: currentCallReply?.id,
      CLAIM: currentCallClaim?.id,
      CANDIDATE: currentCallCandidate?.id,
    };

    const sourceId = sourceIdByType[type];

    if (sourceId && currentUser?.id) {
      const data = {
        ...form,
        managerId: currentUser?.id.toString(),
        managerRemoteId: "",
        sourceId: sourceId.toString(),
        sourceType: type,
      };
      const res = await apollo.mutate({
        mutation: UPDATE_HOT_CALL,
        variables: {
          id: id,
          form: data,
        },
      });
      const callData = res?.data?.updateHotCall;
      if (
        ["FINISH", "BUSY", "NOT_ANSWER", "VOICEMAIL"].includes(
          callData?.status
        ) &&
        callData?.result &&
        action !== "FINISH"
      ) {
        dispatch(setModalResult(false));
        dispatch(setCurrentCallReply(undefined));
        dispatch(setCurrentCallClaim(undefined));
        dispatch(setCurrentCallCandidate(undefined));
        dispatch(setCurrentCallOktellData(undefined));
        dispatch(setCurrentHotCallData(undefined));
        dispatch(setActiveCall(false));
      }
      toaster.success({ title: "Успешно сохранено" });
    } else {
      console.error("source id is not define");
    }
  } catch (err) {
    console.error(err);
    toaster.error({
      title: err?.message || "Ошибка обновления данных звонка",
    });
  } finally {
    dispatch(setLoadingCalls(false));
    dispatch(setModalResult(false));
  }
});

export const sendResultCall = createAsyncThunk<
  void,
  IUpdateCallPayload,
  AsyncThunkConfig
>("calls/sendResultCall", (payload, thunkAPI) => {
  thunkAPI.dispatch(setLoadingCalls(true));
  const { sourceId, sourceStatus } = payload;
  if (sourceId && sourceStatus) {
    if (
      sourceStatus !== "DENIED" &&
      sourceStatus !== "REFUSAL_CANDIDATE" &&
      typeof sourceId === "number"
    ) {
      toSwitchReplyStatus(sourceId as number, sourceStatus as string)
        .then(() => {
          thunkAPI.dispatch(updateCall(payload));
        })
        .catch((err) => {
          console.error(err?.message || err);
          toaster.error({ title: err?.message });
        });
    } else {
      thunkAPI.dispatch(updateCall(payload));
    }
  }
});

export default callsModule.reducer;
