import React, { useCallback, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Form } from "components/UI/Form";
import { Button } from "components/UI/Button";
import { FormInput } from "components/UI/Form/FormInput";
import { FormInputMask } from "components/UI/Form/FormInputMask";
import { FormSelect } from "components/UI/Form/FormSelect";
import { FormSelectDadata } from "components/UI/Form/FormSelectDadata";
import RowLayout from "layouts/RowLayout";
import { Title } from "components/UI/Title";
import Text from "components/UI/Text";
import { Modal } from "components/UI/Modal";
import { useAppSelector, useAppDispatch } from "services/store/store";
import { positions } from "services/store/modules/directory";
import { filterOption, getSelectOptions } from "components/UI/Select/helpers";
import { createCandidate } from "services/store/modules/candidate/actions";
import { checkCandidateByPhone } from "services/store/modules/candidate/actions";
import { FULL_PHONE_LENGTH, PHONE_MASK } from "common/const/masks";
import { getActualAddressErrorMessage } from "../../FormCandidate/helpers";
import { selectSources } from "services/store/modules/candidate/selectors";
import toaster, {
  Notification,
} from "components/UI/Notifications/Notification";
import { CreateCandidateForm, createCandidatePayload } from "./helpers";

import "./styles.scss";

export const CreateCandidate: React.FC = () => {
  const dispatch = useAppDispatch();
  const positionsDirectory = useAppSelector(positions);
  const candidateSources = useAppSelector(selectSources);
  const [existingCandidateId, setExistingCandidateId] = useState<string | null>(
    null
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createdCandidateId, setCreatedCandidateId] = useState<string | null>(
    null
  );

  const {
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors, isValid },
    reset,
  } = useForm<CreateCandidateForm>({
    defaultValues: {
      phone: "",
      secondName: "",
      firstName: "",
      patronymic: "",
      actualAddress: undefined,
      positions: undefined,
      source: undefined,
    },
    mode: "onChange",
  });

  const phoneValue = watch("phone");

  const checkPhoneValue = useCallback(
    async (value: string) => {
      if (!value || value.length !== FULL_PHONE_LENGTH) {
        clearErrors("phone");
        return;
      }

      try {
        const res = await dispatch(
          checkCandidateByPhone({ phone: value })
        ).unwrap();
        setExistingCandidateId(res ? res.checkCandidateByPhone.id : null);
      } catch (err) {
        clearErrors("phone");
        setExistingCandidateId(null);
      }
    },
    [clearErrors, dispatch, setError]
  );

  useEffect(() => {
    checkPhoneValue(phoneValue);
  }, [phoneValue, checkPhoneValue]);

  const onSubmit = useCallback(
    async (data: CreateCandidateForm) => {
      try {
        const payload = createCandidatePayload(data);

        const res = await dispatch(createCandidate({ form: payload })).unwrap();
        setCreatedCandidateId(res?.createCandidate?.id || null);
        setIsModalOpen(true);
        reset();
      } catch (error) {
        toaster.error({ title: error.message });
      }
    },
    [dispatch, reset]
  );

  return (
    <div className="create-candidate-container">
      <Form style={{ padding: "30px 20px" }}>
        <RowLayout
          leftComponent={
            <Controller
              name="phone"
              control={control}
              rules={{ required: "Номер телефона обязателен" }}
              render={({ field, fieldState: { error } }) => (
                <FormInputMask
                  mask={PHONE_MASK}
                  error={error?.message}
                  placeholder="+7"
                  required
                  label="Телефон"
                  className={classNames("candidate-phone-container-input", {
                    "wide-input": true,
                  })}
                  {...field}
                />
              )}
            />
          }
          centerComponent={
            existingCandidateId ? (
              <Notification
                type="warning"
                title={
                  <a
                    href={`/candidate/${existingCandidateId}`}
                    className="create-candidate-container__existing-candidate-link"
                  >
                    Кандидат уже есть в базе
                  </a>
                }
              />
            ) : null
          }
          centerStyle={{ margin: "auto" }}
          rightComponent={<></>}
        />
        <RowLayout
          leftComponent={
            <Controller
              name="secondName"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormInput
                  label="Фамилия"
                  placeholder="Укажите фамилию"
                  error={error?.message}
                  {...field}
                />
              )}
            />
          }
          centerComponent={
            <Controller
              name="firstName"
              control={control}
              rules={{ required: "Имя обязательно" }}
              render={({ field, fieldState: { error } }) => (
                <FormInput
                  label="Имя"
                  placeholder="Укажите имя"
                  required
                  error={error?.message}
                  {...field}
                />
              )}
            />
          }
          rightComponent={
            <Controller
              name="patronymic"
              control={control}
              render={({ field }) => (
                <FormInput
                  label="Отчество"
                  placeholder="Укажите отчество"
                  {...field}
                />
              )}
            />
          }
        />
        <Controller
          name="actualAddress"
          control={control}
          rules={{ required: "Фактический адрес обязателен" }}
          render={({ field }) => (
            <FormSelectDadata
              label="Адрес"
              required
              error={getActualAddressErrorMessage(errors)}
              value={field.value}
              onChange={(value) => field.onChange(value ?? undefined)}
              inputProps={{
                onChange: (event) => {
                  const val = (event.target as HTMLInputElement).value;
                  if (!val) field.onChange(undefined);
                },
              }}
            />
          )}
        />
        <RowLayout
          leftComponent={
            <Controller
              name="positions"
              control={control}
              rules={{ required: "Должность должна быть указана" }}
              render={({ field }) => (
                <FormSelect
                  label="Предпочитаемая должность"
                  mode="multiple"
                  maxTagCount="responsive"
                  maxTagPlaceholder={(tags) => `Ещё ${tags.length}`}
                  showSearch
                  required
                  optionFilterProp="children"
                  filterOption={filterOption}
                  options={getSelectOptions(positionsDirectory)}
                  {...field}
                />
              )}
            />
          }
          centerComponent={
            <Controller
              name="source"
              control={control}
              rules={{ required: "Источник должен быть указан" }}
              render={({ field }) => (
                <FormSelect
                  label="Источник"
                  required
                  options={getSelectOptions(candidateSources, true)}
                  {...field}
                />
              )}
            />
          }
          rightComponent={<></>}
        />
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || !!existingCandidateId}
        >
          Создать
        </Button>
      </Form>
      <Modal
        open={isModalOpen}
        footer={false}
        onCancel={() => setIsModalOpen(false)}
      >
        <div className="candidates-actions-modal">
          <Title type="h3">Карточка кандидата успешно создана</Title>
          <Text>
            <a href={`/recommended/${createdCandidateId}`}>
              Подобрать вакансию
            </a>
          </Text>
        </div>
      </Modal>
    </div>
  );
};
