import React, { useMemo, useEffect, useState, useCallback } from "react";
import { useForm, Controller, SubmitHandler, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router";

import { IAvitoVacInfo } from "components/Vacancy/types";
import { VacancyType, VacancyStatus } from "graphql/types/types";
import { Notification } from "components/UI/Notifications/Notification";
import { getInfo } from "api/hh";
import { Title } from "components/UI/Title";
import { PublicationStatus, publicationStatuses } from "common/const/avito";
import { FormInput } from "components/UI/Form/FormInput";
import { useAppSelector } from "services/store/store";
import {
  selectLanguageLevel,
  selectExperience,
  selectDriverLicense,
  selectAreas,
  selectProfessionalRoles,
  selectEmploymentHH,
  selectScheduleHH,
} from "services/store/modules/directory";
import { FormSelect } from "components/UI/Form/FormSelect";
import FormText from "components/UI/Form/FormText";
import { filterOption, getSelectOptions } from "components/UI/Select/helpers";
import { publishVacancy, updateVacancy } from "api/hh";
import {
  EMPTY_PUBLICATION,
  getContacts,
  getIsDisabledStatuses,
} from "../helpers";
import { Button } from "components/UI/Button";
import RowLayout from "layouts/RowLayout";
import Input from "components/UI/Input";
import { Editor } from "components/UI/Editor";
import { PublicationStatusModal } from "../PublicationStatusModal";
import { VACANCY_CHARACTER_LIMITS } from "common/const/characterLimits";
import { FormTreeSelect } from "components/UI/Form/FormTreeSelect";
import Checkbox from "components/UI/Checkbox";
import { Platform } from "common/const/platform";
import { maskPhone } from "common/utils";
import { schema } from "./validation";
import { getButtontext, getInitialState, getPayload } from "./helpers";
import { IUser } from "services/store/modules/user/types";
import { getAreaTreeOptions } from "components/UI/TreeSelect/helpers";

import "./styles.scss";

interface Props {
  vacancy: VacancyType;
  managerList: IUser[];
  publishItems: Platform[];
  changeTab: (key: Platform) => void;
}

export type HHState = {
  name: string;
  salaryFrom: number;
  salaryTo: number;
  experience: string;
  managerId: number;
  description: string;
  shortDescription: string;
  terms: string;
  claims: string;
  driverLicense: string[];
  languages: string;
  area: { id: string; name: string };
  professionalRoles: { id: string; name: string };
  acceptHandicapped: boolean;
  acceptKids: boolean;
  acceptTemporary: boolean;
  employment?: string;
};

export const HHForm: React.FC<Props> = ({
  vacancy,
  managerList,
  publishItems,
  changeTab,
}) => {
  const history = useHistory();
  const languageLevelOptions = useAppSelector(selectLanguageLevel);
  const experienceOptions = useAppSelector(selectExperience);
  const driverLicenseOptions = useAppSelector(selectDriverLicense);
  const areasOptions = useAppSelector(selectAreas);
  const professionalRolesOptions = useAppSelector(selectProfessionalRoles);
  const employmentHHOptions = useAppSelector(selectEmploymentHH);
  const scheduleHHOptions = useAppSelector(selectScheduleHH);

  const [hhInfo, setHhInfo] = useState<IAvitoVacInfo | null>(null);
  const [loading, setLoading] = useState(false);
  const [isSalaryBlock, setIsSalaryBlock] = useState(false);
  const [publishModal, setPublishModal] = useState(EMPTY_PUBLICATION);

  const initialState = useMemo<HHState>(
    () => getInitialState(vacancy),
    [vacancy]
  );

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm<HHState>({
    // @ts-expect-error
    resolver: yupResolver(schema),
    defaultValues: initialState,
  });
  const managerId = useWatch({
    control,
    name: "managerId",
  });

  const { phone, email, fullName } = useMemo(
    () => getContacts(managerId, managerList),
    [managerId, managerList]
  );

  const statusMessage = useMemo(() => {
    const { publication_status: publicationStatus } = hhInfo || {};
    const status = publicationStatus
      ? publicationStatuses(false, window.location.host, fullName)[
          publicationStatus
        ]
      : undefined;

    return status ? <div dangerouslySetInnerHTML={{ __html: status }} /> : null;
  }, [hhInfo, fullName]);

  const onPublish: SubmitHandler<HHState> = useCallback(
    async (formData) => {
      setLoading(true);
      const payload = getPayload(formData, managerList);
      const callApiVacancy =
        hhInfo?.publication_status === PublicationStatus.Active
          ? updateVacancy
          : publishVacancy;

      callApiVacancy<IAvitoVacInfo>(vacancy.id, managerId, payload)
        .then(({ data }) => {
          setHhInfo(data);
          setPublishModal({
            status: "success",
            message: "Вакансия успешно опубликована",
          });
        })
        .catch((e) => {
          const error =
            e?.response?.data?.detail?.error?.message ||
            e?.response?.data?.detail?.error?.value ||
            "Неизвестная ошибка";

          setPublishModal({
            status: "error",
            message: error,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [managerList, vacancy.id]
  );
  const handleClosePublishModal = () => {
    if (publishModal.status === "success" && publishItems.length >= 2) {
      changeTab("avito");
    } else {
      history.goBack();
    }
    setPublishModal((prev) => ({ ...prev, message: null }));
  };

  useEffect(() => {
    getInfo<IAvitoVacInfo>(vacancy.id, managerId)
      .then(({ data }) => setHhInfo(data))
      .catch((e) => {
        if (
          e?.response?.status === 404 &&
          e.response.data?.detail === "User not found"
        ) {
          setHhInfo({
            ...hhInfo,
            is_active: false,
            is_published: false,
            publication_status: PublicationStatus.NotAuthorized,
          });
          return;
        }

        setHhInfo(null);
      });
  }, [managerId]);

  useEffect(() => {
    reset(initialState);
  }, [initialState, reset]);

  return (
    <div className="hh-form">
      <Title type="h2">Опубликовать вакансию</Title>

      {statusMessage && (
        <Notification
          type={
            hhInfo?.publication_status === PublicationStatus.Active
              ? "success"
              : "error"
          }
          title={statusMessage}
          className="hh-form-notification"
        />
      )}
      {vacancy.status !== VacancyStatus.Published && (
        <Notification
          type="error"
          title='Для публикации на HeadHunter, вакансия должна быть в статусе "Опубликовано"'
          className="hh-form-notification"
        />
      )}

      <Title type="h3" color="blue">
        Основная информация
      </Title>

      <Controller
        name="name"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <FormInput
            error={error?.message}
            label="Название вакансии"
            required={true}
            {...field}
          />
        )}
      />
      <Controller
        name="professionalRoles"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormTreeSelect
            allowClear
            treeDefaultExpandAll
            showSearch
            required={true}
            error={errors.professionalRoles?.name?.message ?? error?.message}
            label={"Специализация"}
            treeData={getAreaTreeOptions(professionalRolesOptions, false)}
            value={value.name}
            onChange={(value, _, { allCheckedNodes }) => {
              onChange({
                name: value,
                id: Array.isArray(allCheckedNodes)
                  ? allCheckedNodes[0]?.node?.props?.id ?? ""
                  : "",
              });
            }}
          />
        )}
      />

      <Controller
        name="area"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormTreeSelect
            label={"Где искать сотрудника"}
            requiredText={
              "Этот параметр нельзя будет изменить после публикации формы"
            }
            treeData={getAreaTreeOptions(areasOptions, true)}
            value={value.name}
            disabled={!!vacancy.extraHh?.state}
            onChange={(value, _, { allCheckedNodes }) => {
              onChange({
                name: value,
                id: Array.isArray(allCheckedNodes)
                  ? allCheckedNodes[0]?.node?.props?.id ?? 0
                  : 0,
              });
            }}
            error={error?.message ?? errors.area?.name?.message}
            allowClear
            treeDefaultExpandAll
            showSearch
            required={true}
          />
        )}
      />

      <RowLayout
        style={{ display: "inlineBlock", alignItems: "center" }}
        leftComponent={
          <Controller
            name="salaryFrom"
            control={control}
            render={({ field }) => (
              <FormInput
                label="Предполагаемый уровень дохода в месяц"
                disabled={isSalaryBlock}
                {...field}
              />
            )}
          />
        }
        leftSpan={12}
        rightComponent={
          <Controller
            name="salaryTo"
            control={control}
            render={({ field }) => (
              <Input disabled={isSalaryBlock} {...field} />
            )}
          />
        }
        rightSpan={12}
      />

      <Checkbox
        type="single"
        className="avito-form-salary-block"
        checked={isSalaryBlock}
        onSingleChange={(e) => {
          const { checked } = e.target;
          setIsSalaryBlock(checked);
          if (checked) {
            setValue("salaryFrom", 0);
            setValue("salaryTo", 0);
          }
        }}
      >
        Договорная заработная плата
      </Checkbox>

      <FormInput
        label="Где будет работать сотрудник"
        value={vacancy.address.value ?? ""}
        disabled={true}
      />

      <Controller
        name="experience"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <FormSelect
            label="Опыт работы"
            required={true}
            error={error?.message}
            options={getSelectOptions(experienceOptions)}
            {...field}
          />
        )}
      />

      <Title type="h3" color="blue">
        Контактные данные
      </Title>

      <FormText label="Телефон" text={maskPhone(phone)} />
      <FormText label="Адрес электронной почты" text={email} />

      <FormInput
        label="Работодатель"
        disabled={true}
        value={vacancy.employer?.name ?? ""}
      />
      <Controller
        name="managerId"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormSelect
            label="Менеджер вакансии"
            showSearch
            required={true}
            //TODO: https://git.infra.rabotut.ru/rabotut/issues-rteam/-/issues/109
            disabled={true}
            optionFilterProp="children"
            filterOption={filterOption}
            options={managerList?.map(({ fullName, id }) => ({
              label: fullName,
              value: id,
            }))}
            {...field}
          />
        )}
      />
      <Title type="h3" color="blue">
        Дополнительно
      </Title>

      <Controller
        name="employment"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <FormSelect
            label="Тип занятости"
            required={true}
            error={error?.message}
            options={getSelectOptions(employmentHHOptions)}
            {...field}
          />
        )}
      />
      <FormSelect
        label="Режим работы"
        disabled={true}
        options={getSelectOptions(scheduleHHOptions)}
        value={vacancy.schedule}
      />

      <Title type="h3" color="blue">
        Оформление вакансии
      </Title>

      <Controller
        name="shortDescription"
        control={control}
        render={({ field }) => (
          <Editor
            disabled={true}
            containerClassName="hh-form-desc"
            label="Описание вакансии"
            count={VACANCY_CHARACTER_LIMITS.SHORT_DESCRIPTION}
            {...field}
          />
        )}
      />

      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <Editor
            containerClassName="hh-form-desc"
            disabled={true}
            label="Обязанности"
            count={VACANCY_CHARACTER_LIMITS.DESCRIPTION}
            {...field}
          />
        )}
      />

      <Controller
        name="terms"
        control={control}
        render={({ field }) => (
          <Editor
            containerClassName="hh-form-desc"
            disabled={true}
            label="Условия"
            count={VACANCY_CHARACTER_LIMITS.TERMS}
            {...field}
          />
        )}
      />

      <Controller
        name="claims"
        control={control}
        render={({ field }) => (
          <Editor
            containerClassName="hh-form-desc"
            disabled={true}
            label="Требования"
            count={VACANCY_CHARACTER_LIMITS.CLAIMS}
            {...field}
          />
        )}
      />

      <Controller
        name="driverLicense"
        control={control}
        render={({ field }) => (
          <FormSelect
            label={"Категория прав"}
            mode="multiple"
            options={driverLicenseOptions.map(({ id, key }) => ({
              label: key,
              value: id,
            }))}
            {...field}
          />
        )}
      />

      <Controller
        name="languages"
        control={control}
        render={({ field }) => (
          <FormSelect
            label={"Уровень русского языка"}
            options={getSelectOptions(languageLevelOptions)}
            {...field}
          />
        )}
      />

      <Controller
        name="acceptHandicapped"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox type="single" checked={value} onSingleChange={onChange}>
            Доступна для соискателей с инвалидностью
          </Checkbox>
        )}
      />

      <Controller
        name="acceptKids"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox type="single" checked={value} onSingleChange={onChange}>
            Доступна для соискателей от 14 лет
          </Checkbox>
        )}
      />

      <Controller
        name="acceptTemporary"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox type="single" checked={value} onSingleChange={onChange}>
            Доступна с временным трудоустройством
          </Checkbox>
        )}
      />

      <Notification
        type="warning"
        title="Повторная публикация вакансии будет невозможна"
        className="hh-form-notification"
      />

      <Button
        loading={loading}
        disabled={
          Boolean(Object.values(errors).length) || getIsDisabledStatuses(hhInfo)
        }
        onClick={handleSubmit(onPublish)}
      >
        {getButtontext(publishItems, hhInfo?.publication_status)}
      </Button>

      <PublicationStatusModal
        isVisible={!!publishModal.message}
        onClose={handleClosePublishModal}
        publication={publishModal}
        jobBoard="headhunter"
      />
    </div>
  );
};
