import { getDateFormat } from "common/utils/date";
import { ReplyFiltersInitialState } from "services/store/modules/repliesNew/types";

export const prepareFilter = ({
  creationDateRange: [startDate, endDate],
  status,
  departmentId,
  userId,
}: ReplyFiltersInitialState) => ({
  userId: userId || null,
  startCreatedAt: startDate ? getDateFormat(startDate, "YYYY-MM-DD") : null,
  endCreatedAt: endDate ? getDateFormat(endDate, "YYYY-MM-DD") : null,
  status: Array.isArray(status) ? status : [status],
  departmentId: departmentId || null,
});
