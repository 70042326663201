import React, { Fragment, useState } from "react";
import cn from "classnames/dedupe";
import { useHistory } from "react-router-dom";

import { UserType, VacancyType } from "graphql/types/types";
import { Title } from "components/UI/Title";
import RowLayout from "layouts/RowLayout";
import { Maps } from "components/UI/Maps";
import { useToggle } from "common/hooks/useToggle";
import { Platform } from "common/const/platform";
import { ROUTE } from "routes";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { hideVacancy } from "services/store/modules/vacancy/actions";
import { deleteVacancy } from "services/store/modules/vacanciesList";
import {
  setVacancyInWork,
  setVacancyPeopleCount,
} from "services/store/modules/vacancy";
import toaster from "components/UI/Notifications/Notification";
import { selectDisabledVacancyList } from "services/store/modules/vacanciesList/selectors";
import { IUser } from "services/store/modules/user/types";
import { getTableRowDisabled } from "common/helpers/table";
import { getVacancyInfoRightColumn, getVacancyInfoLeftColumn } from "./helpers";
import { categories } from "services/store/modules/directory";
import { useChangeVisible } from "common/hooks/visible/useChangeVisible";
import { Spinner } from "components/UI/Spinner";
import { VacancyStatus } from "components/VacancyStatus";
import { PeopleCount } from "./components/PeopleCount";
import { VacancyActions } from "./components/VacancyActions";
import { VacancyModals } from "./components/VacancyModals";
import { getActionButtons } from "./actionButtons";

import "./styles.scss";

type Props = {
  vacancy: VacancyType;
  userData: UserType;
  className?: string;
  managerList?: IUser[];
  publishItems?: Platform[];
  hiddenActions?: boolean;
  mapLoading?: boolean;
  withGetInWork?: boolean;
  withPublish?: boolean;
  withStayInWork?: boolean;
  withPeopleCount?: boolean;
  changeItems?: (val: Platform[]) => void;
  openModal?: () => void;
};

export const VacancyInfo: React.FC<Props> = ({
  vacancy,
  className,
  managerList,
  publishItems,
  changeItems,
  openModal,
  hiddenActions = false,
  withGetInWork = true,
  withPublish = true,
  withStayInWork = true,
  withPeopleCount = true,
  mapLoading,
  userData,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { onChangeVacancyVisible } = useChangeVisible({ id: vacancy.id });

  const disabledVacancyList = useAppSelector(selectDisabledVacancyList);
  const categoriesList = useAppSelector(categories);

  const [openPublish, togglePublish] = useToggle(false);
  const [openInWork, toggleInWork] = useToggle(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const handlePublishClick = () => {
    togglePublish();
    openModal?.();
  };
  const handleClosePublishClick = async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await dispatch(hideVacancy({ id: vacancy.id })).unwrap();

      if (!result.hideVacancy) {
        setError(`Ошибка скрытия вакансии`);
        return;
      }

      dispatch(deleteVacancy({ id: vacancy.id }));
      history.replace(ROUTE.VACANCIES);
    } catch (error) {
      setError(`Ошибка: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const handleVacancyVisible = async () => {
    await onChangeVacancyVisible({ inWork: true })
      .unwrap()
      .then((result) => {
        if (result?.changeVisibleVacancy) {
          const { peopleCountOpen, peopleCountClose } =
            result.changeVisibleVacancy;

          dispatch(
            setVacancyPeopleCount({ peopleCountOpen, peopleCountClose })
          );
        }
        dispatch(setVacancyInWork({ inWork: true }));
        toaster.success({ title: "Вакансия в работе" });
      })
      .catch((error) => {
        toaster.error({ title: error || "Ошибка вакансии" });
      });
  };

  const isVacancyRowDisabled = getTableRowDisabled({
    id: vacancy.id,
    disabledList: disabledVacancyList,
    ownerId: userData.id,
  });

  const actionButtons = getActionButtons({
    withGetInWork,
    withPublish,
    withStayInWork,
    isVacancyRowDisabled,
    status: vacancy.status,
    peopleCountOpen: Boolean(vacancy.peopleCountOpen),
    inWork: Boolean(vacancy.inWork),
    userData,
    handleVacancyVisible,
    togglePublish,
    toggleInWork,
  });

  return (
    <div className={cn(className, "vacancyInfo-container")}>
      <RowLayout
        leftSpan={hiddenActions ? 12 : 10}
        leftComponent={
          <div className="vacancyInfo-container-column global-scrollbar">
            {getVacancyInfoLeftColumn(vacancy).map(
              ({ type, title, color, html, content }, ind) => (
                <Fragment key={ind}>
                  <Title
                    type={type}
                    color={color}
                    className="vacancyInfo-container-title"
                  >
                    {title}
                  </Title>
                  <div
                    className="vacancyInfo-container-short"
                    dangerouslySetInnerHTML={
                      html ? { __html: html } : undefined
                    }
                  >
                    {content}
                  </div>
                </Fragment>
              )
            )}

            {mapLoading ? (
              <Spinner
                size="small"
                className="vacancy-container-table-spinner"
                loading={mapLoading}
              />
            ) : (
              <Maps
                zoom={14}
                width="calc(100% - 16px)"
                height={160}
                placemarks={[
                  {
                    id: String(vacancy.id),
                    lat: Number(vacancy.address.lat),
                    lng: Number(vacancy.address.lng),
                    name: vacancy?.name || "",
                  },
                ]}
              />
            )}
          </div>
        }
        centerSpan={hiddenActions ? 12 : 10}
        centerComponent={
          <div className="vacancyInfo-container-column global-scrollbar">
            {hiddenActions && (
              <div className="vacancyInfo-container-wrapper">
                <div className="vacancyInfo-container-item">
                  <Title
                    type="h3"
                    color="grey"
                    className="vacancyInfo-container-title"
                  >
                    Id Вакансии:
                  </Title>
                  <div className="vacancyInfo-container-short">
                    {vacancy.id}
                  </div>
                </div>
                <VacancyStatus
                  className="vacancyInfo-container-status"
                  status={vacancy.status}
                  hasBorder={true}
                />
              </div>
            )}
            {getVacancyInfoRightColumn(vacancy, categoriesList).map(
              (
                { type, title, color, html, content, isTitle, isShort },
                ind
              ) => (
                <div
                  key={ind}
                  className={cn("vacancyInfo-container-item", {
                    "vacancyInfo-container-item--inline": isShort,
                  })}
                >
                  <Title
                    type={type}
                    color={color}
                    className="vacancyInfo-container-title"
                  >
                    {title}
                  </Title>
                  {isTitle ? (
                    <Title type="h2">{content}</Title>
                  ) : (
                    <div
                      className="vacancyInfo-container-short"
                      dangerouslySetInnerHTML={
                        html ? { __html: html } : undefined
                      }
                    >
                      {content}
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        }
        rightSpan={hiddenActions ? 0 : 4}
        rightComponent={
          hiddenActions ? null : (
            <>
              <VacancyActions
                actionButtons={actionButtons}
                managersIds={vacancy.managersIds}
                managerList={managerList}
              />

              {withPeopleCount && (
                <PeopleCount
                  countOpen={vacancy.peopleCountOpen}
                  countClose={vacancy.peopleCountClose}
                />
              )}
            </>
          )
        }
      />

      <VacancyModals
        publishItems={publishItems}
        openPublish={openPublish}
        openInWork={openInWork}
        loading={loading}
        error={error}
        changeItems={changeItems}
        togglePublish={togglePublish}
        toggleInWork={toggleInWork}
        onPublishClick={handlePublishClick}
        onClosePublishClick={handleClosePublishClick}
      />
    </div>
  );
};
