import { ReplyFiltersInitialState } from "services/store/modules/repliesNew/types";
import { VacancyFiltersState } from "../../components/redezign/Vacancies";

export const INITIAL_STATE_FILTERS_REPlY: ReplyFiltersInitialState = {
  creationDateRange: [null, null],
  status: [],
  userId: null,
  departmentId: null,
};

export const INITIAL_STATE_FILTERS_VACANCY: VacancyFiltersState = {
  vacancyId: "",
  creationDateRange: [null, null],
  statuses: [],
  candidateCount: "",
  city: "",
  department: "",
};
