import { getDateFormat } from "common/utils/date";
import { PAGINATION } from "common/const/pagination";
import { CityType, PaginationType } from "graphql/types/types";
import { VacancyFiltersState } from "./index";
import { VacancyListByCategoryPayload } from "services/store/modules/vacanciesList/types";

const prepareFilters = (
  filtersData: VacancyFiltersState,
  citiesList: CityType[]
) => {
  const {
    vacancyId,
    creationDateRange,
    statuses,
    candidateCount,
    city,
    department,
  } = filtersData;

  const cityName = city
    ? citiesList.find((item) => String(item?.id) === String(city))?.key
    : undefined;

  return {
    vacancyId: Number(vacancyId) || null,
    startCreatedAt: creationDateRange?.[0]
      ? getDateFormat(creationDateRange[0], "YYYY-MM-DD")
      : null,
    endCreatedAt: creationDateRange?.[1]
      ? getDateFormat(creationDateRange[1], "YYYY-MM-DD")
      : null,
    status: Array.isArray(statuses) ? statuses : [statuses],
    countCandidate: Number(candidateCount) || null,
    city: cityName || null,
    departmentId: Number(department) || null,
  };
};

export type PrepareVacanciesPayload = {
  pagination: PaginationType;
  categoryId: number;
  citiesList: CityType[];
  filters?: VacancyFiltersState;
};
export const prepareVacanciesPayload = ({
  pagination,
  categoryId,
  citiesList,
  filters,
}: PrepareVacanciesPayload): VacancyListByCategoryPayload => {
  return {
    categoryId,
    pagination: {
      limit: pagination?.limit || PAGINATION.VACANCY.limit,
      offset: pagination?.offset || PAGINATION.VACANCY.offset,
    },
    filter: filters ? prepareFilters(filters, citiesList) : {},
  };
};
